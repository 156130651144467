body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}

textarea:focus, input:focus{
  outline: none;
}

*:focus {
  outline: none;
}

.blank {
  border-color: rgba(127, 127, 127, 0.1);
  border-style: solid;
  border-width: 1px;
}

.blank-shadow {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
}

.blank-square {
  border-radius: 5px;
}

.blank-circle {
  border-radius: 50%;
  border-color: transparent;
  background-color: rgba(127,127,127,0.1);
}

